.work__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem auto;
    width: 90%;
    margin: 0 auto;
    margin-bottom: 4rem;
}

.work__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.work__header {
    display: flex;
    justify-content: space-between;
}

.title {
    color: var(--color-primary);
}

.location {
    color: var(--color-light);
}

.description {
    margin-top: 2rem;
}

.point {
    list-style: disc;
}

.experience__container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 2rem;
    margin-top: 2rem;
    width: 90%;
    margin: 0 auto;
}

.experience__container > div {
    background: var(--color-bg-variant);
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
}

.experience__container > div:hover {
    background: transparent;
    border: 1px solid var(--color-primary-variant);
    cursor: default;
    transition: var(--transition);
}

.experience__container > div > h3 {
    text-align: center;
    margin-bottom: 2rem;
    color: var(--color-primary);
}

.experience__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    row-gap: 2rem;
}

.experience__details {
    display: flex;
    gap: 1rem;
}

.experience__details-icon {
    margin-top: 0.5rem;
    color: var(--color-primary);
}

/* ========== MEDIA QUERIES (MEDIUM DEVICES) ========== */

@media screen and (max-width: 1024px) {
    .work__container {
        grid-template-columns: 1fr;
    }

    .experience__container {
        grid-template-columns: 1fr;
    }

    .experience__container > div {
        width: 100%;
        margin: 0 auto;
    }

    .experience__content {
        padding: 1rem;
    }
}

/* ========== MEDIA QUERIES (SMALL DEVICES) ========== */

@media screen and (max-width: 768px) {
    .work__header {
        display: grid;
        grid-template-columns: 1fr;
    }

    .experience__container {
        gap: 1rem;
    }

    .experience__container > div {
        width: 100%;
        padding: 2rem 1rem;
    }

    .experience__content {
        grid-template-columns: 1fr;
    }
}